import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

// import p1 from 'assests/Photos/SchoolProgram/2023/hindidiwas/1.jpg';
// import p2 from 'assests/Photos/SchoolProgram/2023/hindidiwas/2.jpg';
// import p3 from 'assests/Photos/SchoolProgram/2023/hindidiwas/3.jpg';
// import p4 from 'assests/Photos/SchoolProgram/2023/hindidiwas/4.jpg';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import { Sidebar2023 } from 'views/SchoolProgram2023/components';

const HindiDivas2023 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });
        
const [config, setConfig] = useState({ base_image_archive_url: '' });
useEffect(() => {
  fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
    .then(response => response.json())
    .then(data => setConfig(data))
    .catch(error => console.error('Error fetching config:', error));
}, []);

const p1 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/hindidiwas/1.webp`;
const p2 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/hindidiwas/2.webp`;
const p3 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/hindidiwas/3.webp`;
const p4 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/hindidiwas/4.webp`;

    const photos = [
        {
            src: p4,
            source: p4,
            rows: 1,
            cols: 2,
        },
        {
            src: p1,
            source: p1,
            rows: 1,
            cols: 2,
        },
        {
            src: p2,
            source: p2,
            rows: 1,
            cols: 2,
        },
        {
            src: p3,
            source: p3,
            rows: 1,
            cols: 2,
        }, 
    ];

    return (
        <Main>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box >
                                <Typography variant='h4' align='center'>
                                Hindi Diwas	
                                </Typography>
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                    Class: 8  Date: 14 SEPTEMBER 2023
                                </Typography>

                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                Hindi Diwas, celebrated on 14th September every year, is a significant occasion for India as it commemorates the adoption of Hindi as the official language of the country. The day is an opportunity to celebrate the rich linguistic heritage of Hindi and promote its continued growth and development. The roots of Hindi Diwas can be traced back to 1949 when the Constituent Assembly of India adopted Hindi as the official language of the nation. Hindi, written in the Devanagari script, was chosen for its widespread usage.
                                    <br></br>
                                    In our school, Hindi Diwas was celebrated with great zeal and enthusiasm. Students showcased the rich heritage of Hindi literature through informative speech intriguing riddles and exhilarating music. The audience was very excited and participated actively in answering the riddles.
                                    <br></br>
                                    Overall, students enjoyed the Programme and witnessed the celebration of India's diverse culture and heritage through Hindi Diwas .
                                </Typography>
                            </Box>
                            <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                                “हिन्दी हमारी शान है, हमारा अभिमान है |”

                                <br />
                            </Typography>

                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 400 : 200}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                    <Box marginBottom={4}>
                            <Sidebar2023/>
                        </Box> 
                    </Grid>
                </Grid>
            </Container>
        </Main>

    );
};

export default HindiDivas2023;